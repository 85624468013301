import { useCallback } from 'react';
import { useMutation } from 'react-query';

import type { APIError } from '@/utils/react-query-utilities/error';
import type { BaseResponse } from '@/model/types';
import type { FMOptions } from '@/utils/react-query-utilities/fetcher-mutation';

import fetcherMutation from '@/utils/react-query-utilities/fetcher-mutation';
import { ENDPOINTS } from '@/constants/endpoints';

import type { IsExistEmailPayload, IsExistEmailResponse } from '../../model/is-exist-email';

interface Dependencies {
  onSuccess: (data: IsExistEmailResponse) => void;
  onError: (error: APIError<BaseResponse>) => void;
}

/**
 * @function useIsExistEmail
 * @params deps
 */
const useIsExistEmail = (deps: Dependencies) => {
  const { onSuccess, onError } = deps;
  const { isLoading, mutate } = useMutation<
    IsExistEmailResponse,
    APIError<BaseResponse>,
    FMOptions<IsExistEmailPayload>
  >({
    mutationFn: fetcherMutation,
    onSuccess: onSuccess,
    onError: onError,
  });

  /**
   * @function _onVerification
   * @params data
   */
  const _onVerification = useCallback(
    (data: IsExistEmailPayload) => {
      const context = {
        path: ENDPOINTS.GET_IS_EMAIL_EXIST,
        method: 'GET',
      };

      mutate({ context: context, variables: data });
    },
    [mutate],
  );

  return {
    verifying: isLoading,
    onVerification: _onVerification,
  };
};

export default useIsExistEmail;
