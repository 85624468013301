import type { VFC } from 'react';
import Head from 'next/head';

/**
 * @function PageMeta
 * @params props
 */
const PageMeta: VFC<unknown> = () => {
  return (
    <Head>
      <title key="title">Masuk | BestHome</title>
      <meta key="meta-description" name="description" content="Silakan login / masuk ke BestHome." />

      <meta key="og:title" property="og:title" content="Masuk | BestHome" />
      <meta key="og:description" property="og:description" content="Silakan login / masuk ke BestHome" />
    </Head>
  );
};

export default PageMeta;
