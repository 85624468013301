import type { VFC, MouseEvent } from 'react';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { G500 } from '@/styles/color';
import emailRules from '@/utils/react-hook-form-validate/email';
import noop from '@/utils/fp/noop';

interface Props {
  disabled?: boolean;
  onEdit?: () => void;
}

/**
 * @function InputEmail
 * @params props
 */
const InputEmail: VFC<Props> = props => {
  const { disabled = false, onEdit = noop } = props;
  const { control } = useFormContext();

  /**
   * @function handleOnMouseDown
   * @param event
   */
  const handleOnMouseDown = useCallback((event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
  }, []);

  return (
    <Controller
      control={control}
      name="email"
      rules={{
        required: 'Email harus diisi!',
        validate: emailRules.basic,
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          id="email"
          label="Email"
          autoComplete="email"
          margin="normal"
          fullWidth
          disabled={disabled}
          error={fieldState.invalid}
          helperText={fieldState.error ? fieldState.error.message : ''}
          InputProps={{
            endAdornment: disabled ? (
              <InputAdornment position="end">
                <Typography
                  variant="body2-bold"
                  component="span"
                  color={G500}
                  onClick={onEdit}
                  onMouseDown={handleOnMouseDown}
                >
                  Ubah
                </Typography>
              </InputAdornment>
            ) : undefined,
          }}
        />
      )}
    />
  );
};

export default InputEmail;
