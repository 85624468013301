import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import getErrorMessage from '@/utils/react-query-utilities/get-error-message';

import type { APIError } from '@/utils/react-query-utilities/error';
import type { BaseResponse } from '@/model/types';
import type { NotificationPayload } from '@/context/notification';

import type { IsExistEmailResponse } from '../model/is-exist-email';

interface Dependencies {
  pushNotification: (payload: NotificationPayload) => void;
}

/**
 * @function useRegistrationCheck
 */
const useRegistrationCheck = (deps: Dependencies) => {
  const { pushNotification } = deps;
  const { push } = useRouter();
  const [registered, setRegistered] = useState(false);

  /**
   * @function _onVerificationSuccess
   * @params data
   */
  const _onVerificationSuccess = useCallback(
    (data: IsExistEmailResponse) => {
      const { data: payload } = data;
      const isAlreadyRegistered = (payload && payload.is_exist) ?? false;

      if (isAlreadyRegistered) {
        setRegistered(true);
      } else {
        push('/register');
      }
    },
    [push],
  );

  /**
   * @function _onVerificationError
   * @params error
   */
  const _onVerificationError = useCallback(
    (error: APIError<BaseResponse>) => {
      const messages = getErrorMessage(error) || 'Gagal Men-cek email';
      pushNotification({
        message: messages,
        variant: 'error',
        duration: 3000,
      });
    },
    [pushNotification],
  );

  /**
   * @function _onResetVerification
   */
  const _onResetVerification = useCallback(() => {
    setRegistered(false);
  }, []);

  return {
    registered: registered,
    onResetVerification: _onResetVerification,
    onVerificationError: _onVerificationError,
    onVerificationSuccess: _onVerificationSuccess,
  };
};

export default useRegistrationCheck;
