import type { Validate } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';

/**
 * @function basic Basic email validator function
 * @param value
 */
const basic: Validate<string> = value => {
  if (value && !isEmail(value)) {
    return 'Format email salah!';
  }
  return undefined;
};

const EMAIL_RULES = {
  basic: basic,
};

export default EMAIL_RULES;
