import { styled } from '@mui/material/styles';
import theme from '@/styles/material/theme';

import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';

import type { GridProps } from '@mui/material/Grid';
import MuiGrid from '@mui/material/Grid';

export const Group = styled(Box)<BoxProps>({
  '&.MuiBox-root': {
    marginTop: theme.spacing(2),
  },
});

export const Grid = styled(MuiGrid)<GridProps>({
  '& [role="separator"]': {
    margin: theme.spacing(2, 0),
  },
});
