import { useCallback } from 'react';
import { useMutation } from 'react-query';

import type { BaseResponse } from '@/model/types';
import type { FMOptions } from '@/utils/react-query-utilities/fetcher-mutation';
import type { APIError } from '@/utils/react-query-utilities/error';

import fetcherMutation from '@/utils/react-query-utilities/fetcher-mutation';
import { ENDPOINTS } from '@/constants/endpoints';

import type { LoginResponse, LoginFormData } from '../../model/login';

interface Dependencies {
  onSuccess: (data: LoginResponse) => void;
  onError: (error: APIError<BaseResponse>) => void;
}

/**
 * @function useLogin
 * @param deps
 */
const useLogin = (deps: Dependencies) => {
  const { onSuccess, onError } = deps;
  const { isLoading, mutate } = useMutation<LoginResponse, APIError<BaseResponse>, FMOptions<LoginFormData>>({
    mutationFn: fetcherMutation,
    onSuccess: onSuccess,
    onError: onError,
  });

  /**
   * @function onLogin
   * @param data
   */
  const _onLogin = useCallback(
    (data: LoginFormData) => {
      const context = {
        path: ENDPOINTS.POST_LOGIN,
      };

      mutate({ context: context, variables: data });
    },
    [mutate],
  );

  return {
    loading: isLoading,
    onLogin: _onLogin,
  };
};

export default useLogin;
