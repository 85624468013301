import type { VFC } from 'react';
import { useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import Link from 'next/link';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import Form from '@/routes/Login/presentations/Form';
import InputEmail from '@/routes/Login/components/LoginForm/InputEmail';
import InputPassword from '@/routes/Login/components/LoginForm/InputPassword';
import ButtonStep from '@/routes/Login/components/LoginForm/ButtonStep';
import ButtonGroup from '@/routes/Login/components/LoginForm/ButtonGroup';

import { G500 } from '@/styles/color';
import noop from '@/utils/fp/noop';

import type { LoginFormData } from '@/routes/Login/model/login/types';
import type { IsExistEmailPayload } from '@/routes/Login/model/is-exist-email';

interface LoginFormProps {
  loading?: boolean;
  registered?: boolean;
  verifying?: boolean;
  onEmailVerification?: (data: IsExistEmailPayload) => void;
  onLogin?: (data: LoginFormData) => void;
  onResetVerification?: () => void;
}

/**
 * @function LoginForm
 * @params props
 */
const LoginForm: VFC<LoginFormProps> = props => {
  const {
    loading = false,
    registered = true,
    verifying = false,
    onEmailVerification = noop,
    onLogin = noop,
    onResetVerification = noop,
  } = props;
  const { handleSubmit, trigger, resetField, ...methods } = useForm<LoginFormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const emailValue = methods.watch('email') ?? '';
  const passwordValue = methods.watch('password') ?? '';
  const isEligibleToCheck = emailValue.length !== 0;
  const isEligibleToSubmit = emailValue.length !== 0 && passwordValue.length !== 0;

  /**
   * @function handlOnResetVerification
   */
  const handlOnResetVerification = useCallback(() => {
    resetField('password');
    onResetVerification();
  }, [onResetVerification, resetField]);

  /**
   * @function handleOnEmailVerification
   * @params data
   */
  const handleOnEmailVerification = useCallback(
    (data: LoginFormData) => {
      onEmailVerification({ email: data.email });
    },
    [onEmailVerification],
  );

  /**
   * @function handleOnNext
   */
  const handleOnNext = useCallback(async () => {
    const valid = await trigger('email');
    if (!valid) {
      return;
    }

    handleSubmit(handleOnEmailVerification)();
  }, [handleSubmit, handleOnEmailVerification, trigger]);

  return (
    <FormProvider {...methods} resetField={resetField} trigger={trigger} handleSubmit={handleSubmit}>
      <Form<LoginFormData> onSubmit={onLogin} onSubmitCallback={handleSubmit}>
        <InputEmail disabled={registered} onEdit={handlOnResetVerification} />
        {registered ? <InputPassword /> : null}
        {registered ? (
          <FormControl margin="dense" fullWidth>
            <Link href="/forgot-password" passHref>
              <Typography
                sx={{ display: 'inline-flex', marginLeft: 'auto' }}
                variant="body2-bold"
                component="a"
                color={G500}
              >
                Lupa Kata Sandi?
              </Typography>
            </Link>
          </FormControl>
        ) : null}
        <ButtonGroup>
          <ButtonStep disabled={!isEligibleToCheck} done={registered} verifying={verifying} onNext={handleOnNext}>
            <LoadingButton
              sx={{ py: 1.5 }}
              type="submit"
              variant="contained"
              size="large"
              fullWidth
              disabled={!isEligibleToSubmit}
              disableElevation
              loading={loading}
            >
              <Typography variant="body1-bold" component="span">
                Masuk
              </Typography>
            </LoadingButton>
          </ButtonStep>
        </ButtonGroup>
      </Form>
    </FormProvider>
  );
};

export default LoginForm;
