import type { VFC, ReactNode } from 'react';

import { Grid, Group } from './styles';

interface Props {
  children: ReactNode;
}

/**
 * @function ButtonGroup
 * @params props
 */
const ButtonGroup: VFC<Props> = props => {
  const { children } = props;
  return (
    <Group>
      <Grid container direction="column">
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </Group>
  );
};

export default ButtonGroup;
