import type { VFC } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

interface Props {
  mobile?: boolean;
}

/**
 * @function Heading
 * @params props
 */
const Heading: VFC<Props> = props => {
  const { mobile = false } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant={mobile ? 'h1' : 'h2'} component="h2">
          Masuk atau Daftar
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={mobile ? 'body1' : 'body2'} component="span">
          Untuk memulai, silahkan masukan email kamu
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Heading;
