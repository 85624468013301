import type { VFC, ReactNode } from 'react';
import { isValidElement } from 'react';

import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import noop from '@/utils/fp/noop';

interface Props {
  children: ReactNode;
  disabled?: boolean;
  done?: boolean;
  verifying?: boolean;
  onNext?: () => void;
}

/**
 * @function ButtonStep
 * @params props
 */
const ButtonStep: VFC<Props> = props => {
  const { children, disabled = false, verifying = false, done = false, onNext = noop } = props;

  if (done && isValidElement(children)) {
    return children;
  }
  return (
    <LoadingButton
      sx={{ py: 1.5 }}
      type="button"
      variant="contained"
      size="large"
      disabled={disabled}
      loading={verifying}
      fullWidth
      disableElevation
      onClick={onNext}
    >
      <Typography variant="body1-bold" component="span">
        Lanjut
      </Typography>
    </LoadingButton>
  );
};

export default ButtonStep;
