import { useCallback } from 'react';

import getErrorMessage from '@/utils/react-query-utilities/get-error-message';

import type { BaseResponse } from '@/model/types';
import type { APIError } from '@/utils/react-query-utilities/error';
import type { NotificationPayload } from '@/context/notification';

import type { LoginResponse } from '../model/login';

interface Dependencies {
  pushNotification: (payload: NotificationPayload) => void;
}

/**
 * @function useLoginEvent
 * @param deps
 */
const useLoginEvent = (deps: Dependencies) => {
  const { pushNotification } = deps;

  /**
   * @description Redirect to home page after successful login
   * @function _onSuccess
   * @param data
   */
  const _onSuccess = useCallback((data: LoginResponse) => {
    const { data: payload } = data;
    if (!payload || !payload.is_success) {
      return;
    }
    window.location.replace('/');
  }, []);

  /**
   * @description Push error message to notification provider
   * @function _onError
   * @param data
   */
  const _onError = useCallback(
    (error: APIError<BaseResponse>) => {
      const messages = getErrorMessage(error) || 'Gagal Login';
      pushNotification({
        message: messages,
        variant: 'error',
        duration: 3000,
      });
    },
    [pushNotification],
  );

  return {
    onSuccess: _onSuccess,
    onError: _onError,
  };
};

export default useLoginEvent;
