import type { ReactNode, ReactElement } from 'react';
import type { UseFormHandleSubmit, FieldValues } from 'react-hook-form';

import { useCallback } from 'react';
import Box from '@mui/material/Box';

interface Props<FV extends FieldValues> {
  children: ReactNode;
  onSubmit: (data: FV) => void;
  onSubmitCallback: UseFormHandleSubmit<FV>;
}

/**
 * @function Form
 * @params props
 */
function Form<FV extends FieldValues>(props: Props<FV>): ReactElement<any, any> | null {
  const { children, onSubmit, onSubmitCallback } = props;

  /**
   * @function _onSubmit
   * @param data
   */
  const _onSubmit = useCallback(
    (data: any) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  return (
    <Box component="form" sx={{ width: '100%' }} noValidate onSubmit={onSubmitCallback(_onSubmit)}>
      {children}
    </Box>
  );
}

export default Form;
