import type { VFC, MouseEvent } from 'react';
import { useState, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/**
 * @function InputPassword
 * @params props
 */
const InputPassword: VFC<unknown> = () => {
  const [show, setShow] = useState(false);
  const { control } = useFormContext();

  /**
   * @function handleOnTogglePassword
   */
  const handleOnTogglePassword = useCallback(() => {
    setShow(prev => !prev);
  }, []);

  /**
   * @function handleOnMouseDownTogglePassword
   * @param event
   */
  const handleOnMouseDownTogglePassword = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return (
    <Controller
      control={control}
      name="password"
      rules={{
        required: 'Password harus diisi!',
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          id="password"
          type={show ? 'text' : 'password'}
          label="Password"
          autoComplete="current-password"
          margin="normal"
          fullWidth
          error={fieldState.invalid}
          helperText={fieldState.error ? fieldState.error.message : ''}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleOnTogglePassword}
                  onMouseDown={handleOnMouseDownTogglePassword}
                >
                  {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default InputPassword;
